#footer {
  background: $brand-primary url("../images/footer-background.png") no-repeat center top;
  box-shadow: 1px 2px 15px rgba(0,0,0,.45);
  border-top: solid 2px #e7e7e7;
  position: relative;
  padding: 52px 0;
  z-index: 10;
  
  #footer-content {
    background: url("../images/footer-content-background.png") repeat-y center;
    position: relative;
    padding: 30px 0;
    color: #252525;
    text-align: center;
    
    &:before,
    &:after {
      display: block;
      content: "";
      width: 100%;
      height: 18px;
      font-size: 0;
      position: absolute;
      left: 0;
    }
    
    &:before {
      top: -18px;
      background: url("../images/footer-shadow-top.png") no-repeat center bottom;
    }
    
    &:after {
      bottom: -18px;
      background: url("../images/footer-shadow-bottom.png") no-repeat center top;
    }
  }
  
  .social-links {
    a {
      display: inline-block;
      vertical-align: top;
      color: #f4e3e4;
      background: #555;
      width: 37px;
      height: 37px;
      line-height: 37px;
      text-align: center;
      border-radius: 37px;
      text-decoration: none;
      margin: 0 5px;
      font-size: 20px;
      transition: all .3s ease-in-out;
      
      &:hover {
        background: $brand-primary;
        color: #fff;
      }
    }
  }
  
  .footer-copyright {
    padding: 10px 0;
  }
  
  .site-creator {
    font-size: 12px;
    
    img {
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px;
    }
  }
}