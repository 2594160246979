// Glyphicons font path
$icon-font-path:              "../fonts/";

// Grid settings
$main-sm-columns:             12;
$sidebar-sm-columns:          4;
$grid-gutter-width:           30px;
$container-large-desktop:     (1080px + $grid-gutter-width);
$screen-lg:                   1150px;
$screen-md:                   992px;
$screen-md-min:               $screen-md;
$grid-float-breakpoint:       $screen-md-min;

// Colors
$brand-primary:               #bf1e2e;
$text-color:                  #464646;
$body-bg:                     #c2c2c2;


// Fonts
$font-family-sans-serif:      'Open Sans', sans-serif;
$font-size-base:              14px;
$line-height-base:            1.857142;
$headings:                    'h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6';
$headings-line-height:        1.5;
$headings-font-family:        $font-family-sans-serif;
$headings-font-weight:        600;
$font-size-h1:                24px;
$font-size-h2:                18px;
$font-size-h3:                16px;
$font-size-h4:                14px;
$font-size-h5:                13px;
$font-size-h6:                12px;

// Forms
$border-radius-base:          15px;
$input-border:                #555555;
$input-color:                 #363636;