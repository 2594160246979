body {
  background: $body-bg url("../images/pattern.png") repeat center top;
}

#page {
  overflow: hidden;
  position: relative;
  background: url("../images/flags-background.png") no-repeat center -57px;
  
  body.home & {
    background-position: center top;
  }
  
  @media (min-width: 1500px) {
    background-size: 100% auto;
  }
}

.wow {
  visibility: hidden;
}

ul, ol {
  margin-left: 0;
  padding-left: 1em;
}

#{$headings} {
  margin-bottom: .7em;
}

p, ul, ol {
  margin-bottom: 1.25em;
}

#{$headings} {
  margin-bottom: .5em;
  color: $brand-primary;
}

p, ul, ol, blockquote, #{$headings} {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

#page-content {
  padding: 129px 0 62px;
  
  body.home & {
    padding-top: 471px;
    
    @media (max-width: $grid-float-breakpoint-max) {
      padding-top: 200px;
    }
  }
}

.white-box {
  min-height: calc(100vh - 576px);
  background: #fff;
  box-shadow: 2px 2px 15px rgba(0,0,0,.29);
  position: relative;
  
  &:before {
    display: block;
    content: "";
    position: absolute;
    left: 25%;
    top: 314px;
    bottom: 0;
    right: 0;
    background: url("../images/white-box-bg.png") no-repeat left top;
    content: "";
    z-index: 0;
  }
  
  > div {
    position: relative;
    z-index: 5;
    padding: 40px;
  }
  
  @media (max-width: $screen-xs-max) {
    > div {
      padding: 30px;
    }
  }
  
  @media (max-width: $screen-xs) {
    > div {
      padding: 20px;
    }
  }
}

.page-section {
  margin: 0 0 30px;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  .buckets {
    @include clearfix;
    margin: 0 -15px;
    
    body.home & {
      margin-top: -80px;
    }
    
    .bucket {
      width: 33.333%;
      float: left;
      padding: 0 15px 10px;
      
      > div {
        position: relative;
      }
    }
    
    .bucket-image {
      max-width: 244px;
      margin: 0 auto 20px;
      
      img {
        border: solid 6px #fff;
        box-shadow: 2px 2px 10px rgba(0,0,0,.33);
        border-radius: 100%;
        transition: all .3s ease-in-out;
      }
    }
    
    .bucket-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      text-decoration: none;
      
      &:hover + .bucket-image img {
        border-color: lighten($brand-primary, 15%);
      }
    }
    
    @media (max-width: $grid-float-breakpoint-max) {
      .bucket {
        width: auto;
        float: none;
        max-width: 360px;
        margin: auto;
        
        & + .bucket {
          margin-top: 30px;
        }
      }
    }
  }
  
  .text-section {
    margin: auto;
    
    &.section-width-medium {
      max-width: 660px;
    }
    
    &.section-width-small {
      max-width: 480px;
    }
    
    &:before {
      display: block;
      content: "";
      width: 280px;
      max-width: 75%;
      margin: 0 auto 30px;
      border-top: solid 1px #ccc;
      font-size: 0;
    }
  }
  
  .two-columns-row {
    max-width: 920px;
    margin: auto;
    
    .column {
      padding: 0 15px;
      float: left;
      width: 50%;
      
      &:before {
        display: block;
        content: "";
        width: 200px;
        max-width: 75%;
        margin: 0 auto 30px;
        border-top: solid 1px #ccc;
        font-size: 0;
      }
      
      &.column-border-left {
        &:before {
          margin-left: 0;
          margin-right: 0;
        }
      }
      
      &.column-border-right {
        &:before {
          margin-left: auto;
          margin-right: 0;
        }
      }
      
      &.column-border-hidden {
        &:before {
          border-color: transparent;
        }
      }
      
      &.large-column {
        width: 60%;
      }
      
      &.small-column {
        width: 40%;
      }
    }
    
    @media (max-width: $grid-float-breakpoint-max) {
      .column {
        float: none;
        width: auto;
        
        & + .column {
          margin-top: 30px;
        }
        
        &.large-column,
        &.small-column {
          width: auto;
        }
      }
    }
  }
  
  .services {
    padding: 20px 0 0;
    
    .service-item {
      position: relative;
      
      &:before {
        display: block;
        content: "";
        width: 280px;
        max-width: 75%;
        margin: 0 auto 25px;
        border-top: solid 1px #ccc;
        font-size: 0;
      }
      
      > div {
        padding: 0 0 25px;
        margin: 0 -15px;
        position: relative;
        @include clearfix;
      }
      
      .service-image {
        width: 35%;
        padding: 0 15px;
        float: left;
        
        & + .service-text {
          width: 65%;
          float: left;
        }
        
        > div {
          max-width: 210px;
          padding: 0 0 30px;
          margin: auto;
        }

        img {
          border: solid 6px #fff;
          box-shadow: 2px 2px 10px rgba(0,0,0,.33);
          border-radius: 100%;
          transition: all .3s ease-in-out;
        }
      }

      .service-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        text-decoration: none;

        &:hover + .service-image img {
          border-color: lighten($brand-primary, 15%);
        }
      }
      
      .service-text {
        padding: 10px 15px 25px;
      }
    }
    
    @media (max-width: $screen-xs-max) {
      .service-item {
        .service-image {
          width: auto;
          float: none;
          
          & + .service-text {
            width: auto;
            float: none;
          }
        }
      }
    }
  }
}

.border-image {
  img {
    box-shadow: 1px 2px 8px rgba(0,0,0,.48);
    border: solid 3px #fff;
  }
}

.contact-information-column {
  h3 {
    margin-top: -15px;
  }
}