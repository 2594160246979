#header {
  position: relative;
  z-index: 100;
  border-bottom: solid 2px #c9d9ed;
  box-shadow: 1px 2px 15px rgba(0,0,0,.45);
  background-color: #bf1e2e;
  background-image: linear-gradient(to top, #8f0d1a, #bf1e2e);
  color: #fff;
  
  &:before {
    display: block;
    content: "";
    font-size: 0;
    width: 884px;
    height: 46px;
    position: absolute;
    top: 0;
    right: 0;
    background: url("../images/nav-shadow.png") no-repeat left top;
    z-index: 0;
  }
  
  a {
    color: inherit;
  }
  
  .brand {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -39px;
    left: $grid-gutter-width/2;
    float: left;
  }
  
  .container {
    padding-left: 380px;
  }
  
  #top-row {
    text-align: right;
    padding: 10px 0;
    color: #f6e3e3;
    line-height: 26px;
    
    span {
      font-size: 15px;
      display: inline-block;
      vertical-align: top;
      
      & + span {
        margin-left: 15px;
      }
      
      a {
        font-weight: bold;
      }
    }
  }
  
  @media (min-width: $grid-float-breakpoint) {
    #navigation {
      float: right;
      padding: 20px 0;
      
      .nav {
        display: block;
        float: left;
        list-style: none;
        margin: 0;
        padding: 0;
        
        > li {
          display: block;
          position: relative;
          float: left;
          
          > a {
            display: block;
            float: left;
            line-height: 25px;
            color: #f6e3e3;
            text-decoration: none;
            font-size: 18px;
            font-weight: 600;
            padding: 0;
            top: 0;
            transition: top .3s ease-in-out;
            
            &:hover,
            &:focus,
            &:active {
              top: -3px;
              background: none;
            }
          }
          
          &.active {
            > a {
              color: #fff;
              
              &:before {
                position: absolute;
                top: 50%;
                margin-top: -4px;
                left: -18px;
                width: 11px;
                height: 9px;
                font-size: 0;
                display: block;
                content: "";
                background: url("../images/nav-arrow.png") no-repeat left top;
                background-size: 11px 9px;
              }
            }
          }
          
          & + li {
            margin-left: 35px;
          }
        }
      }
      
      #top-row-mobile {
        display: none;
      }
    }
    
    @media (max-width: $screen-md-max) {
      #navigation {
        .nav {
          > li {
            & + li {
              margin-left: 28px;
            }
          }
        }
      }
    }
  }
  
  @media (max-width: $grid-float-breakpoint-max) {
    &:before {
      display: none;
    }
    
    .container {
      padding-left: $grid-gutter-width/2;
    }
    
    #top-row {
      display: none;
    }
    
    .brand {
      max-width: calc(100% - 90px);
      top: 5px;
      margin: 0;
      line-height: 78px;
      
      img {
        vertical-align: middle;
        display: inline-block;
      }
    }
    
    .navbar-toggle {
      border-radius: 6px;
      margin: 30px 0;
      border: solid 1px #fff;
      
      .icon-bar {
        background: #fff;
      }
    }
    
    #navigation {
      clear: both;
      margin: 0 -15px;
      padding: 0;
      border: none;
      box-shadow: none;
      
      .nav {
        border-top: solid 1px #fff;
        padding: 20px 0;
        margin: 10px 0 0;
        
        > li {
          > a {
            color: #f6e3e3;
            position: relative;
            font-size: 16px;
            font-weight: 600;
            padding: 10px 15px;
            transition: padding .3s ease-in-out;
            
            &:hover,
            &:focus,
            &:active {
              background: transparent;
              padding: 10px 10px 10px 20px;
            }
          }
          
          &.active {
            > a {
              color: #fff;
              padding: 10px 10px 10px 20px;
              
              &:before {
                position: absolute;
                top: 50%;
                margin-top: -4px;
                left: 5px;
                width: 11px;
                height: 9px;
                font-size: 0;
                display: block;
                content: "";
                background: url("../images/nav-arrow.png") no-repeat left top;
                background-size: 11px 9px;
              }
            }
          }
        }
      }
      
      #top-row-mobile {
        display: block;
        padding: 0 20px 20px;
        font-size: 16px;
        font-weight: 600;
        
        a {
          display: block;
          line-height: 20px;
          
          .fa {
            width: 25px;
            display: inline-block;
            vertical-align: top;
            line-height: 20px;
            font-size: 20px;
          }
          
          & + a {
            margin-top: 5px;
          }
        }
      }
    }
  }
}