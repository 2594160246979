// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.form-group {
  margin: 0 0 10px;
}

.form-control {
  height: auto;
  line-height: 19px;
  padding: 5px 20px;
  
  &::-moz-placeholder {
    color: $input-color;
    font-style: italic;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $input-color;
    font-style: italic;
  }
  &::-webkit-input-placeholder {
    color: $input-color;
    font-style: italic;
  }
}

div.wpcf7 {
  margin: 2em 0 0;
  
  span.wpcf7-not-valid-tip {
    display: block;
    margin: 0 20px;
    font-size: 13px;
  }
  
  span.ajax-loader {
    width: 43px;
    height: 11px;
    display: block;
    margin: 10px auto;
    background: url("../images/ajax-loader.gif") no-repeat center;
  }
  
  div.wpcf7-response-output {
    margin: 1em 0 0;
    padding: 15px;
    background: #fff;
  }
}